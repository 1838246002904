




  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .booking-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .booking-item {
    width: 100%;

    max-width: 300px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  
    &:hover {
      transform: scale(1.05);
    }
  }
  
  .booking-image {
    width: 100%;
    height: auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  
  
  
  
  /* Media queries for responsiveness */
  @media (min-width: 768px) {
    .booking-item {
      width: calc(50% - 20px);
      margin-right: 20px;
    }
  
    .booking-item:nth-child(even) {
      margin-right: 0;
    }
  }
  
  @media (min-width: 1200px) {
    .booking-item {
      width: calc(33.33% - 20px);
      margin-right: 20px;
    }
  
    .booking-item:nth-child(3n) {
      margin-right: 0;
    }
  }
  




  
