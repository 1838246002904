/* Booking2.css */

body {
    margin: 0;
    padding: 0;

  }
  
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  
  .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #ef0909;
    border-radius: 50%;

    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  header {
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-size: 17px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: #f3f7f8;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 0px;
  }
  
  label {
    margin-bottom: 15px;
  }
  
  input,
  select,
  textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 0px;
  }
  
  textarea {
    resize: vertical;
  }
  
  button {
    padding: 10px;
    font-size: 16px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Responsive Styles */
  
  @media only screen and (max-width: 600px) {
    form {
      padding: 10px;
    }
  }
  
  @media only screen and (max-width: 400px) {
    header {
      padding: 10px;
    }
  
    form {
      padding: 5px;
    }
  
    input,
    select,
    textarea {
      font-size: 14px;
    }
  
    button {
      font-size: 14px;
    }
  }
  
  
  
