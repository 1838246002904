


.slider-container {
  position: relative;
  width: 100%;
  max-width: 800px; /* Adjust the max-width as needed */
  margin: 0 auto;
  overflow: hidden;

}

button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

button:first-child {
  left: 10px;
}

button:last-child {
  right: 10px;
}
