.logo-item {
    cursor: pointer;
    transition: transform 0.2s; /* Add smooth transition for the zoom effect */
    margin-bottom: 20px;
  }
  
  .logo-item:hover {
    transform: scale(1.1); /* Increase the size on hover (zoom effect) */
  }
  
  .logo-img {
    width: 40%;
    border-radius: 50%;
  }
  

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }
  
  th, td {
    text-align: left;
    padding: 16px;
  }
 