/* CSS */
.booking-btn {
    /* Existing styles */
    background-color: green; /* Default background color */
    transition: background-color 0.3s ease; /* Smooth transition */
    font-family: "Mukta", sans-serif;

  }
  
  .booking-btn:hover {
    background-color: rgb(4, 233, 4); /* Change background color on hover */
  }
  
  .booking-btns {
    /* Existing styles */
    background-color: red; /* Default background color */
    transition: background-color 0.3s ease; /* Smooth transition */
    font-family: "muktha", sans-serif;

  }
  
  .booking-btns:hover {
    background-color: rgb(201, 7, 7); /* Change background color on hover */
  }
  .container{
    font-family: "muktha", sans-serif;

      font-weight: 500;
      font-style: normal; 
     }