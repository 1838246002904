



.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-wrap: wrap;
  }
  
  .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #797776;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .booking-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .booking-item {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  
    &:hover {
      transform: scale(1.05);
    }
  }
  
  .booking-image {
    width: 100%;
    height: auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  .booking-description {
    text-align: center;
    margin: 10px 0;
    font-weight: bold;
  }
  
  .booking-button2 {
    /* Existing styles */
    background-color: red; /* Default background color */
    transition: background-color 0.3s ease; /* Smooth transition */
  }
  
  .booking-button2:hover {
    background-color: rgb(201, 7, 7); /* Change background color on hover */
  }

  
  /* Media queries for responsiveness */
  @media (min-width: 768px) {
    .booking-item {
      width: calc(50% - 20px);
      margin-right: 20px;
    }
  
    .booking-item:nth-child(even) {
      margin-right: 0;
    }
  }
  
  @media (min-width: 1200px) {
    .booking-item {
      width: calc(33.33% - 20px);
      margin-right: 20px;
    }
  
    .booking-item:nth-child(3n) {
      margin-right: 0;
    }
  }
  




  

  
 

.booking-item {
    text-align: center;
    margin: 20px;
}

.booking-image {
    width: 100%;
    max-height: 250px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
}

.booking-description {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.booking-button {
    padding: 10px 20px;
    background-color: #007bff;
    
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.booking-button:hover {
    background-color: #0056b3;
}
.booking-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.booking-item {
    flex: 0 0 45%; /* Adjust the percentage as needed */
    margin: 10px;
    text-align: center;
}

@media (max-width: 768px) {
    .booking-item {
        flex: 0 0 100%; /* Full width on small screens */
    }
}
/* Add this to your CSS file or style block */
.container {
    display: flex;
    justify-content: center;
}

.image-container {
    text-align: center;
    margin: 0 20px;
}

.responsive-image {
    width: 100%;
    max-width: 100%;
    height: auto;
}



.table-container {
  overflow-x: auto;
}

.responsive-table {
  width: 85%;
  border-collapse: collapse;
  margin: auto;
}

.responsive-table th,
.responsive-table td {
  border: 1px solid black;
  padding: 10px;
  text-align: center;
}

.responsive-table th {
  background-color: #f11c1c;
}

@media (max-width: 600px) {
  .responsive-table th,
  .responsive-table td {
    padding: 5px;
  }
}

.service-option {
  display:inline-flex;
  align-items: center;
}

.service-option input[type="radio"] {
  margin-right: 5px;
}








