.scroll-to-top {
    position: fixed;
   top:630px;
   width: 50px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    outline: none;
    transition: opacity 0.3s;
    border-radius: 20%;
    justify-self:end;
    
}
  
  .scroll-to-top:hover {
    opacity: 0.8;
  }
  