
.dancing-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height as needed */
}

.dancing-image {
  animation: danceAnimation 2s infinite; /* Adjust the animation duration as needed */
}

@keyframes danceAnimation {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}


/* Former.css */

/* Add a class for the hover effect */


/* Add underline style to the links */
.rounded-5 a {
  text-decoration: none; /* Remove default underline */
  position: relative;
}

.rounded-5 a:after {
  content: '';
  position: absolute;
  width: 60%;
  height: 2px;
  background-color: #000; /* Change the underline color */
  bottom: 0;
  left: 0;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.rounded-5 a:hover:after {
  visibility: visible;
  transform: scaleX(1);
}

.dark{
  background-color: rgb(56, 52, 52);
  color: white;
}


