.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* App.css */
.highlighted {
  color: red;
  /* Add more styles as needed */
}
/* BookingForm.css */


.filled {
  /* Styles applied when input is filled */
  border-color: green;
}

.name-filled {
  /* Additional styles when the name input is filled */
  background-color: lightyellow;
}
/* BookingForm.css */
.boy {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: auto;
}

label {
  margin-bottom: 10px;
}

input {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  margin-bottom: 12px;
}

button {
  background-color: #8fb7f2;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  left: 7px;
  position:7px;
  border-radius: 10px;
}

div.redy{
  border-top: #61dafb;
}
.booking-form {
  max-width:400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #060606;
  border-radius: 8px;
}

label {
  display: block;
  margin-bottom: 10px;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}



button:hover {
  background-color: #22f33d;
}
.booking-form {
  padding: 20px;
  background-color: #7cedfc;
}

.booking-form label {
  display: block;
  margin-bottom: 10px;
}

.booking-form input,
.booking-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
button{
  background-color: #d7e5d8;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  top: 30px;
  position: relative;
display: flex;
justify-content: center;
}
 
button{
  border: #282c34;
}
/* ResponsiveDrawer.css */

.drawerIconButton {
  margin-right: 16px;
}

.drawer {
  width: 200px;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

}

.loader {
position: relative;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #0a0a0af8;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* Form.css */

/* Style for select element */
select {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-bottom: 1rem; /* Add some bottom margin for spacing */
}

/* Additional styles if needed */
