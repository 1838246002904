/* About.css */

.about-container {
    max-width: 500px; /* Adjust the max-width based on your design */
    margin: 0 auto; /* Center the container */
    padding: 50px; /* Add some padding for spacing */
    font-family: "Mukta", sans-serif;
    font-weight: 600;
    font-style: normal; 
}
  
  .responsive-image {
    width: 100%; /* Make the image responsive by setting its width to 100% */
    height: auto; /* Allow the height to adjust proportionally */
     /* Add border-radius for rounded corners */
   padding-right: 18px;
     /* Add a subtle box shadow for a professional look */
  }
  
  .container{
    padding-top: 10px;
    font-size:large;
    

  }
div.robo{
padding-right: 30px;
 
}
