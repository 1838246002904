.container {
  display: flex;
  align-items: center;

}

.image {
  max-width: 40%; /* Adjust the width as needed */
  margin-right: 20px; /* Adjust the margin as needed */
}

.text-container {
  max-width: 50%; /* Adjust the width as needed */
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    text-align: center;

  }

  .image,
  .text-container {
    max-width: 100%;
    margin-right: 0;
  }
}
