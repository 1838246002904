
.dancing-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height as needed */

}

.dancing-image {
  animation: danceAnimation 2s infinite; /* Adjust the animation duration as needed */
}

@keyframes danceAnimation {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}








.hello {
  color: #333; /* Text color */
  font-size: 24px; /* Font size */
  font-weight: bold; /* Bold text */
  margin: 0;
}

